<template>
  <site-menu />

  <slot></slot>

  <custom-whatsapp-button
    :telefone="t('NumeroWhatsappFranquia')"
    :texto="t('TextoWhatsappFranquia')"
  />

  <site-rodape />
</template>

<script>
export default {
  name: "FranquiaLayout",
};
</script>

<script setup>
const { t } = useI18n();
</script>
